<template>
  <div>
    <BaseContainer class="relative pt-10 z-20 pb-20 md:pb-32">
      <HeroIntro
        :title="hero.title"
        :subtitle="hero.subtitle"
        :cta="hero.cta"
      />

      <Partners class="mt-6" v-if="loaded" />
      <Donations class="mt-6" v-if="loaded" />
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import HeroIntro from "@/components/common/HeroIntro"
import Partners from "@/components/OpenData/Partners"
import Donations from "@/components/OpenData/Donations"
import Footer from "@/components/common/Footer"

export default {
  name: "OpenData",
  components: {
    HeroIntro,
    Partners,
    Donations,
    Footer,
  },
  data() {
    return {
      hero: {
        title: "Open Data",
        subtitle:
          "Transparency is a key for us at Cuddle. This is the place where you can see all transactions.",
      },
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("partners/bind"),
      this.$store.dispatch("donations/bind"),
      this.$store.dispatch("exchangeRates/bind")
    ])
  },
  computed: {
    loaded() {
      return this.loadedExchangeRates
    },
    loadedExchangeRates() {
      return (
        this.$store.state.exchangeRates.loadingState.exchangeRates == "loaded"
      )
    },
  },
}
</script>
