<template>
  <div class="py-4" v-if="loaded">
    <BaseHeadingAndText
      heading="Donations"
      headingSize="3xl"
      headingColor="cdlpink"
      textSize="xl"
    />
    <BaseTable :table="table" :loading="!loaded" />
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import _ from "lodash"

export default {
  name: "Donations",
  mixins: [mixinGlobal],
  components: {},
  data() {
    return {
      progressCreate: false,
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    donations() {
      return this.$store.state.donations.donations
    },
    donationsSorted() {
      return _.orderBy(this.donations, ["timestamp"], ["desc"])
    },
    loaded() {
      return (
        this.$store.state.partners.loadingState.partners == "loaded" &&
        this.$store.state.donations.loadingState.donations == "loaded" &&
        this.$store.state.exchangeRates.loadingState.exchangeRates == "loaded"
      )
    },
    totalDonationsCount() {
      let total = 0
      if (this.partners && this.partners.length > 0) {
        this.partners.map((p) => {
          if (!p.demo && !isNaN(parseInt(p.totalDonationsCount)))
            total += parseInt(p.totalDonationsCount)
        })
      }
      return total
    },
    totalETH() {
      return this.roundAmount(
        this.partners.reduce(function (a, b) {
          console.log(a,b)
          if (b.demo) return a
          else return a + b.totalDonations.ETH
        }, 0)
      )
    },
    totalUSD() {
      let that = this
      return this.roundAmount(
        this.partners.reduce(function (a, b) {
          if (b.demo) return a
          else return a + that.convert(b.totalDonations.ETH)
        }, 0),
        0
      )
    },
    table() {
      let t = {
        head: [
          [
            {c: "hash", align: "left"},
            {c: "from"},
            {c: "to"},
            {c: "time"},
            {c: "value"},
            {c: "asset"},
            {c: "eth"},
            {c: "usd"},
          ],
        ],
        body: [],
        foot: [
          [
            {c: this.totalDonationsCount},
            {c: ""},
            {c: ""},
            {c: ""},
            {c: ""},
            {c: ""},
            {c: this.totalETH},
            {c: this.totalUSD.toLocaleString()},
          ],
        ],
      }

      this.donationsSorted.map((tx) => {
        t.body.push([
          {
            c: tx.hash,
            truncate: true,
            mono: true,
            // routerLink: {name: "donation", params: {donationId: tx.id}},
            href: `https://etherscan.io/tx/${tx.hash}`
          },
          {c: tx.from, truncate: true, mono: true, href: `https://etherscan.io/address/${tx.from}`},
          {c: this.partnerName(tx.partnerId), href: `https://etherscan.io/address/${tx.to}`},
          {c: tx.timestamp.toDate().toLocaleString()},
          {c: this.roundAmount(tx.value)},
          {c: tx.asset},
          {c: this.convert(tx.value, tx.asset, "ETH", 3)},
          {c: this.convert(tx.value, tx.asset, "USD", 3)},
        ])
      })

      return t
    },
  },
  methods: {
    partnerName(partnerId) {
      return this.partners.find((p) => p.id == partnerId)?.name
    },
  },
}
</script>

<style lang="less" scoped>
.partner-eth-address {
  cursor: copy;
}
</style>
