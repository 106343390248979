<template>
  <div class="py-4" v-if="loaded">
    <BaseHeadingAndText
      heading="Partners"
      headingSize="3xl"
      headingColor="cdlpink"
      textSize="xl"
    />

    <BaseTable :table="table" :loading="!loaded" />
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"

export default {
  name: "Partners",
  mixins: [mixinGlobal],
  components: {},
  data() {
    return {
      progressCreate: false,
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    loaded() {
      return (
        this.$store.state.partners.loadingState.partners == "loaded" &&
        this.$store.state.exchangeRates.loadingState.exchangeRates == "loaded"
      )
    },
    totalPartners() {
      return this.$store.getters["partners/totalPartners"]
    },
    totalDonationsCount() {
      return this.$store.getters["partners/totalDonationsCount"]
    },
    totalETH() {
      return this.$store.getters["partners/totalETH"]
    },
    totalUSD() {
      return this.$store.getters["partners/totalUSD"]
    },
    table() {
      let t = {
        head: [
          [
            // {c: "", align: "left"},
            {c: "name", align: "left"},
            {c: "eth address"},
            {c: "donations"},
            {c: "total eth"},
            {c: "total usd"},
          ],
        ],
        body: [],
        foot: [
          [
            // {c: ""},
            {c: this.totalPartners},
            {c: ""},
            {c: this.totalDonationsCount},
            {c: this.totalETH},
            {c: this.totalUSD.toLocaleString()},
          ],
        ],
      }

      this.partners.map((p) => {
        t.body.push([
          // {c: null},
          {
            c: p.name,
            ignored: p.demo,
            // routerLink: {name: "partner", params: {partnerId: p.id}},
          },
          {
            c: p.ethAddress,
            truncate: true,
            mono: true,
            // clickToCopy: true,
            ignored: p.demo,
            href: `https://etherscan.io/address/${p.ethAddress}`
          },
          {
            c: p.totalDonationsCount ? p.totalDonationsCount : 0,
            ignored: p.demo,
          },
          {c: this.roundAmount(p.totalDonations.ETH), ignored: p.demo},
          {
            c: this.convert(
              p.totalDonations.ETH,
              "ETH",
              "USD",
              0
            ).toLocaleString(),
            ignored: p.demo,
          },
        ])
      })

      return t
    },
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.partner-eth-address {
  cursor: copy;
}
</style>
